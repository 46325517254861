/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    jQuery.fn.doesExist = function(){
        return jQuery(this).length > 0;
    };

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    ajax_selector: function(){
      $('#state-select').change(function(){
        var stateSelect = $('#state-select').val();
        //event.preventDefault();
        $("#town-select").empty();
        var spinner = $('<span id="town-progress"><i class="fa fa-spinner fa-pulse fa-spin fa-2x" aria-hidden="true"></i></span>');
        spinner.insertAfter('#town-select');

        $.ajax({
          url: '/wp-admin/admin-ajax.php',
          type: 'POST',
          data: {
            action: 'ajax_selector',
            state_id: stateSelect
          },
          success: function(results) {
            spinner.remove(); 
            $('#town-select').removeAttr("disabled");
            $("#town-select").append(results);
            },
          error: function(error){
            console.log('FGL ajax error');
            }
          });
        });
    },
    ajax_change_calendar: function(){
      $('#change-calendar').change(function(){
        var post_id = $('#change-calendar').val();
        event.preventDefault();

        var spinner = $('<span id="calendar-progress"><i class="fa fa-spinner fa-pulse fa-spin fa-2x" aria-hidden="true"></i></span>');
        var style = $('<style>.fc-view-container{opacity:0.2;}</style>');
        style.insertBefore('.fc-view-container');
        spinner.insertAfter('.fc-view-container');

        $.ajax({
          url: '/wp-admin/admin-ajax.php',
          type: 'POST',
          dataType:'json',
          data: {
            action: 'ajax_change_calendar',
            post_id: post_id
          },
          success: function(results) {
            style.remove();
            spinner.remove();
            $('#calendar').fullCalendar('removeEventSources');
            $('#calendar').fullCalendar('addEventSource', {
                  googleCalendarId: results.thirty_hour,
                  className: 'thirty-hour-calendar'
            });
            $('#calendar').fullCalendar('addEventSource', {
                  googleCalendarId: results.eight_hour,
                  className: 'eight-hour-calendar'
            });
            $('#calendar').fullCalendar('addEventSource', {
                  googleCalendarId: document.getElementById('calendar').dataset.calendaridroadtest,
                  className: 'road-test-calendar'
            });
            $('#calendar').fullCalendar('rerenderEvents');
            //console.log(results);
            },
          error: function(error){
            console.log('FGL Ajax Error');
            }
          });
        });
    },
    location_submit: function(){
      $('#location-submit').click(function() {
          var town = $('#town-select').val();
          if (isNaN(town)){
            location.href = town;
          }
      });
    },
    navigation: function(){
      $('.location-nav-toggle').on('click', function(){
          $('body').toggleClass('location-nav-open');
        });
      $('.location-navigation .navbar-header').on('click', function(){
          $('body').toggleClass('location-nav-open');
        });
      $('.main-nav-toggle').on('click', function(){
          $('body').toggleClass('main-nav-open');
        });
      $('#main-navigation li.dropdown').on('click', function(){
          $(this).toggleClass('li-open');
        });
       var fgl_hash = window.location.hash;
        if(fgl_hash && $('.nav a[href="' + fgl_hash + '"]')){
          $('.nav a[href="' + fgl_hash + '"]').tab('show');
        }
      $('.location-nav > a').on('click', function (e) {
        location_hash = $(this).prop("hash");
        $('a[href="' + location_hash + '"]').tab('show');
          $('#tab-select').val(location_hash);
          $('#tab-select').trigger("change");
        });
    },
    my_location_cookie: function(){
      change_location = document.getElementById('change-location');
      my_location_btn = document.getElementById('my-location-btn');
      $('#my-location-btn').on('click', function(){
          Cookies.set('my_location', my_location_btn.dataset.cookieid,{ expires: 30 });
          location.reload();
      });
      $('#change-location').on('change', function(){
          Cookies.set('my_location', change_location.options[change_location.selectedIndex].value,{ expires: 30 });
          location.reload();
      });
      //var my_location_btn_cookieid = document.body.contains(my_location_btn) ? my_location_btn.dataset.cookieid : null;
      if (Cookies.get('my_location') === my_location_btn.dataset.cookieid || Cookies.get('my_location') === change_location.options[change_location.selectedIndex].value){
        $('#my-location-btn').addClass('active');/*.text('My Location')*/
        $('.change-location-container .select2').addClass('hidden-xs');
      }
      else{
        $('.change-location-container .select2').addClass('hide');
      }
    },
    after_calendar_render: function(){
        console.log('running after calendar render');
       if ($('.fc-thirtyHourBtn-button').hasClass('fc-state-active')){
            $('.thirty-hour-calendar').show();
            $('.eight-hour-calendar').hide();
            $('.road-test-calendar').hide();
            $('.five-hour-calendar').hide();
          } else if ($('.fc-eightHourBtn-button').hasClass('fc-state-active')){
            $('.eight-hour-calendar').show();
            $('.thirty-hour-calendar').hide();
            $('.road-test-calendar').hide();
            $('.five-hour-calendar').hide();
          } else if ($('.fc-roadTestBtn-button').hasClass('fc-state-active')){
            $('.road-test-calendar').show();
            $('.thirty-hour-calendar').hide();
            $('.eight-hour-calendar').hide();
            $('.five-hour-calendar').hide();
          } else if ($('.fc-fiveHourBtn-button').hasClass('fc-state-active')){
            $('.five-hour-calendar').show();
            $('.road-test-calendar').hide();
            $('.thirty-hour-calendar').hide();
            $('.eight-hour-calendar').hide();
          }
      },
    google_calendar: function(apiKey,calendarID1,calendarID2,calendarID3,calendarID4){
        $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {          
            $('#calendar').fullCalendar('render');
            $('body:not(.new-york) .fc-right > .fc-button-group > .fc-button:first-child').addClass('fc-state-active');
            $('body.new-york .fc-right > .fc-button-group > .fc-button:last-child').addClass('fc-state-active');
            console.log('calling after calendar render');
            Sage.after_calendar_render();
        });
        $('#calendar').fullCalendar({
              googleCalendarApiKey: apiKey,
              height: 900,
              timezone: 'America/New_York',
              customButtons: {
                    thirtyHourBtn: {
                        text: '30 HR',
                        click: function(){
                          $('.fc-button-group > .fc-button').removeClass('fc-state-active');
                          $(this).addClass('fc-state-active');
                          $('.thirty-hour-calendar').show();
                          $('.eight-hour-calendar').hide();
                          $('.road-test-calendar').hide();
                          $('.five-hour-calendar').hide();
                        }
                    },
                    eightHourBtn: {
                        text: '8 HR',
                        click: function(){
                          $('.fc-button-group > .fc-button').removeClass('fc-state-active');
                          $(this).addClass('fc-state-active');
                          $('.eight-hour-calendar').show();
                          $('.thirty-hour-calendar').hide();
                          $('.road-test-calendar').hide();
                          $('.five-hour-calendar').hide();
                        }
                    },
                    roadTestBtn: {
                        text: 'Road Test',
                        click: function(){
                          $('.fc-button-group > .fc-button').removeClass('fc-state-active');
                          $(this).addClass('fc-state-active');
                          $('.road-test-calendar').show();
                          $('.thirty-hour-calendar').hide();
                          $('.eight-hour-calendar').hide();
                          $('.five-hour-calendar').hide();
                        }
                    },
                    fiveHourBtn: {
                        text: '5 HR',
                        click: function(){
                          $('.fc-button-group > .fc-button').removeClass('fc-state-active');
                          $(this).addClass('fc-state-active');
                          $('.five-hour-calendar').show();
                          $('.road-test-calendar').hide();
                          $('.thirty-hour-calendar').hide();
                          $('.eight-hour-calendar').hide();
                        }
                    }
                },
              header: {
                left: 'prev,next today',
                center: 'title',
                right: 'thirtyHourBtn,eightHourBtn,roadTestBtn,fiveHourBtn'
              },
              eventAfterAllRender: function(){
                Sage.after_calendar_render();
              }
        });
        if(calendarID1){
          $('#calendar').fullCalendar('addEventSource', {
              googleCalendarId: calendarID1,
              className: 'thirty-hour-calendar'
          });
        }
        if(calendarID2){
          $('#calendar').fullCalendar('addEventSource', {
              googleCalendarId: calendarID2,
              className: 'eight-hour-calendar'
          });
        }
        if(calendarID3){
          $('#calendar').fullCalendar('addEventSource', {
              googleCalendarId: calendarID3,
              className: 'road-test-calendar'
          });
        }
        if(calendarID4){
          $('#calendar').fullCalendar('addEventSource', {
              googleCalendarId: calendarID4,
              className: 'five-hour-calendar'
          });
        }
    },
    youtube_player: function(){
      $('.youtube-video > .entry-content-asset > iframe').attr('id','youtube-video');
      $("iframe").contents().find(".ytp-large-play-button-bg").css("fill", "#fff !important");
      var player;
      function onYouTubeIframeAPIReady() {
        player = new YT.Player('#youtube-video', {
          playerVars: { 'autoplay': 1, 'controls': 0 },
          events: {
            'onReady': onPlayerReady,
            'onStateChange': onPlayerStateChange
          }
        });
      }
    },
    slick_testimonials_slider: function(){
       $('.testimonials-slider').slick({
          dots: true,
          infinite: false,
          arrows: false
        });
    },
    tooltip: function(){
      $('.tooltipster').tooltipster({
        contentAsHTML: true,
        theme: 'tooltipster-borderless',
        trigger: 'hover',
        forceSide: true,
        side: 'top',
        interactive: true,
        maxWidth: 400,
        viewportAware: false,
        delay: 50,
        animationDuration: 20,
        functionReady: function(){ 
        $('.tt-close').click(function(){
          $('.tooltippy-bottom').tooltipster('hide');
            });
          }
      });
    },
    convert_tabs_to_select: function(){
        $('#tab-select').on('change', function (e) {
          var id = $(this).val();
          $('a[href="' + id + '"]').tab('show');
        });
    },
    select2_arrow: function(){
      $('b[role="presentation"]').hide();
      $('.select2-selection__arrow').append('<i class="fa fa-angle-down fa-2x"></i>');
    },
    load_more: function(parent,element,button,wrap){
        var btn = $(parent+' '+button);
        var wrapper = $(parent+' '+wrap);
        var loadMoreRow = $(parent+' '+element);
        loadMoreRow.hide();
        loadMoreRow.slice(0, 3).show();
        if(wrapper.children().length < 4){
          btn.hide();
        }
          btn.on('click', function (e) {
            e.preventDefault();
            $(parent+' '+element+':hidden').slice(0, 3).slideDown();
            if(wrapper.children().slice(-2,-1).is(':visible') ) {
              btn.hide();
            } else {
              btn.show();
            }
        });
    },
    flex_center: function(rowClass){
      var rowClassElement = $(rowClass);
        var rowChildren = rowClassElement.children().length;
        if (rowChildren === 1){
            rowClassElement.addClass('flex-center');
          }
    },
    home_hero_height: function(){
        var screenHeight = $(window).height();
        var headerHeight = $('header.navbar').height();
        var locationSelectHeight = $('.location-select-container').height();
        var pageHeroHeight = screenHeight - headerHeight - locationSelectHeight;
        $('#page-hero').height(pageHeroHeight);
    },
    tel_on_click_event: function(){
      $('a').each(function() {
        switch($(this).attr("href")){
            case("tel:2038611188"):
              $(this).on('click',function(){
                ga('send', 'event', { eventCategory: 'phone', eventAction: 'click', eventLabel: 'call ct'});
              });
            break;
             case("tel:8477933100"):
              $(this).on('click',function(){
                ga('send', 'event', { eventCategory: 'phone', eventAction: 'click', eventLabel: 'call il'});
              });
            break;
             case("tel:9149218888"):
              $(this).on('click',function(){
                ga('send', 'event', { eventCategory: 'phone', eventAction: 'click', eventLabel: 'call ny'});
              });
            break;
        }
      });
    },
    print_section: function(){
      $('#location-calendar-print-button').on('click',function(){
        $('.location-calendar-container').printThis({
          debug: false,               // show the iframe for debugging 
          importCSS: true,            // import page CSS 
          importStyle: false,         // import style tags 
          printContainer: true,       // grab outer container as well as the contents of the selector 
          pageTitle: "",              // add title to print page 
          removeInline: false,        // remove all inline styles from print elements 
          printDelay: 333,            // variable print delay; depending on complexity a higher value may be necessary 
          header: null,               // prefix to html 
          footer: null,               // postfix to html 
          base: false ,               // preserve the BASE tag, or accept a string for the URL 
          formValues: true,           // preserve input/form values 
          canvas: false,              // copy canvas elements (experimental) 
          doctypeString: "...",       // enter a different doctype for older markup 
          removeScripts: false        // remove script tags from print content 
      });
    });

    },
    // All pages
    'common': {
      init: function() {
        Sage.navigation();
        Sage.ajax_selector();
        $('.select-dropdown').select2({
          minimumResultsForSearch: Infinity
        });
        $('#tab-select').select2({
          minimumResultsForSearch: Infinity
        });
        $('.change-location').select2({
          minimumResultsForSearch: Infinity
        });
        Sage.location_submit();
        Sage.slick_testimonials_slider();
        Sage.convert_tabs_to_select();
        Sage.select2_arrow();
        Sage.load_more('#featured-videos-tab','.load-item','#loadMore','.load-more-wrap');
        Sage.load_more('#instructional-videos-tab','.load-item','#loadMore','.load-more-wrap');
        Sage.load_more('#about-videos-tab','.load-item','#loadMore','.load-more-wrap');
        // JavaScript to be fired on all pages
        $(window).resize(function(){
          // On Resize
        });
      },
      finalize: function() {
        Sage.tooltip();
        Sage.youtube_player();
        Sage.tel_on_click_event();
        Sage.print_section();
        // JavaScript to be fired on all pages, after page specific JS is fired
        $(window).load(function(){
          // Run After Jquery
        });
      }
    },
    'single_location': {
      init: function(){
        Sage.flex_center('.teen-row');
        Sage.flex_center('.adult-row');
        Sage.my_location_cookie();
        Sage.google_calendar(
          document.getElementById('calendar').dataset.calendarapi,
          document.getElementById('calendar').dataset.calendaridthirty,
          document.getElementById('calendar').dataset.calendarideight,
          document.getElementById('calendar').dataset.calendaridroadtest,
          document.getElementById('calendar').dataset.calendaridfive
          );
        Sage.ajax_change_calendar();
        $('#change-calendar').select2({
          minimumResultsForSearch: Infinity
        });
        $('#change-calendar').next().insertAfter('.fc-center > h2');
        Sage.select2_arrow();
      }
    },
    'page_template_template_resources': {
      init: function(){
        Sage.flex_center('.quick-links-row.dmv-links');
        Sage.flex_center('.quick-links-row.parent-links');
        /*Sage.vimeo_player();*/
      }
    },
    'page_template_template_press': {
      init: function(){
        Sage.flex_center('.quick-links-row.dmv-links');
        Sage.flex_center('.quick-links-row.parent-links');
        Sage.load_more('#featured-videos','.load-item','#loadMore','.load-more-wrap');
        /*Sage.vimeo_player();*/
      }
    },
    // Home page
    'home': {
      init: function() {
        //Sage.home_hero_height();
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
